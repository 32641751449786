<template>
    <div class="wrapper" v-if="survey">
        <div class="container mb-5">
            <!-- Form settings -->
            <div class="p-4 bg-white border rounded-lg">
                <h4>Settings - Appearance {{ survey.code }}</h4>
                <br>
                <!-- General settings -->
                <div class="row settings-section">
                    <div class="col-lg-4 settings-sidebar" style="text-align: right">
                        <br>
                        <h4>Welcome Message</h4>
                        <!--<p>Customize the appearance of the application header and navigation sidebar.</p>-->
                    </div>
                    <div class="col-lg-6 settings-content">
                        <div class="mb-3">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="p-1">Welcome Message:</label>
                                        <textarea class="form-control" rows="4" placeholder="Welcome Message"
                                                v-model="message.welcome_message"  required>
                                        </textarea>
                                    </div>
                                </div>
                        </div>
                        <div class="mb-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="multiple_responses"
                                    v-model="welmsg_activate"
                                />
                                <label class="form-check-label" for="multiple_responses">Show Welcome Message</label>
                            </div>
                            <div class="form-text">
                                Your respondents will be able to see this message.
                            </div>
                        </div>

                        <!-- <div class="mb-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="form_description"
                                    v-model="survey.show_description"
                                />
                                <label class="form-check-label" for="form_description"
                                >Show form description</label
                                >
                            </div>
                            <div class="form-text">
                                Show the survey description on the survey landing page.
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- End general settings -->

                <!-- Visibility settings -->
                <div class="row settings-section">
                    <div class="col-lg-4 settings-sidebar" style="text-align: right">
                        <h4>End Message</h4>
                        <!--<p>Customize the appearance of the application header and navigation sidebar.</p>-->
                    </div>
                    <div class="col-lg-6 settings-content">
                          <div class="mb-3">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="p-1">End Message:</label>
                                        <textarea class="form-control" rows="4" placeholder="End Message"
                                                v-model="message.end_message"  required>
                                        </textarea>
                                    </div>
                                </div>
                        </div>
                          <div class="mb-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="multiple_responses"
                                    v-model="endmsg_activate"
                                />
                                <label class="form-check-label" for="multiple_responses">Show Ending Message</label
                                >
                            </div>
                            <div class="form-text">
                                Your respondents will be able to see this message.
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End visibility settings -->

                <!-- Date settings -->
                <div class="row settings-section">
                    <div class="col-lg-4 settings-sidebar" style="text-align: right">
                        <h4>Publish Settings</h4>
                        <!--<p>Customize the appearance of the application header and navigation sidebar.</p>-->
                    </div>
                    <div class="col-lg-6 settings-content">
                        <div class="mb-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="schedule_survey"
                                    name="schedule_survey"
                                />
                                <label class="form-check-label" for="schedule_survey"
                                >Schedule this survey</label
                                >
                            </div>
                            <div class="form-text">
                                Schedule this survey to start and stop at a specific date and
                                time.
                            </div>
                        </div>

                        <div class="row g-2">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <div class="">
                                        <label>Start Date</label>
                                        <Datepicker id="start_date" :bootstrap-styling="true"
                                                    :disabledDates="disabledDates"
                                                    v-model="survey.start_date" maximum-view="day" :disabled="wait"
                                                    placeholder="Start date" :required="true">
                                        </Datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label" for="start_time"
                                    >Start time</label
                                    >
                                    <input
                                        class="form-control"
                                        type="time"
                                        id="start_time"
                                        name="start_time"
                                        disabled="disabled"
                                        v-model="survey.start_date"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="">
                                    <label>End Date</label>
                                    <Datepicker id="end_date" :bootstrap-styling="true" :disabledDates="disabledDates"
                                                v-model="survey.end_date" maximum-view="day" :disabled="wait"
                                                placeholder="End date" :required="true">
                                    </Datepicker>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label" for="end_time">End time</label>
                                    <input
                                        class="form-control"
                                        type="time"
                                        id="end_time"
                                        name="end_time"
                                        disabled="disabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <button @click.prevent="updateSurvey()" class="custom-btn custom-btn-success">
                            Save Settings
                        </button>
                    </div>
                </div>
            </div>
            <!-- End survey settings -->
        </div>
        <!-- End container-lg -->
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
    name: "Settings",
    props: ['survey'],
    components: {
        Datepicker
    },
    data() {
        return {
            id: '',
            title: '',
            message: {
                welcome_message: '',
                end_message: ''
            },
            welmsg_activate: '',
            endmsg_activate: '',
            start_date: new Date(),
            end_date: new Date(),
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1)),
                from: new Date(new Date().setDate(new Date().getDate() + 30))
            },
        }
    },
    mounted() {
        this.survey.category_ids = this.survey.categories.map(a => a.id)
    },
    methods: {
        updateSurvey() {
            this.$http.post('/api/survey/create', this.survey).then(res => {
                this.success = "Survey updated successfully!"
                this.survey = res.data.data
                this.survey.category_ids = this.survey.categories.map(a => a.id)
                window.scroll(0, 0)
            })
        },
    }

}
</script>

<style scoped>
</style>